/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useParams } from "react-router-dom";
import { Backdrop, CircularProgress, CssBaseline } from "@mui/material";
import AppApi from "./api";
import NotAuth from "./notauth";
import { isIpAddressOrDomain } from ".././../utils/functions";
import { validate } from "uuid";

import Page404 from "./Page404";
import NoData from "./NoData";
import useAuth from "../../hooks/useAuth";

const LayoutInfo = ({ user }) => {
  const [meta, setMeta] = useState({
    data: null,
    loading: false,
  });
  // const { signOut } = useAuth();

  let { id } = useParams();

  const [error, setError] = useState(null);
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  // String with valid UUID separated by dash

  const isValidUUID = regexExp.test(id);

  // localStorage.setItem("lang", "tj");
  const fetchData = async (id) => {
    if (!isValidUUID) return;
    setMeta({ ...meta, loading: true });
    try {
      const { data } = await AppApi.getMeta(id);

      setMeta({ data: data, loading: false });
    } catch (err) {
      setError(err);

      setMeta({ data: null, loading: false });
    }
  };

  useEffect(() => {
    fetchData(id);
  }, []);

  return (
    <>
      <CssBaseline />

      {meta.loading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={meta.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : !isValidUUID ? (
        !!user ? (
          <Navigate to={"/dashboard"} />
        ) : (
          <Page404 />
        )
      ) : error ? (
        <NoData />
      ) : (
        <NotAuth id={id} data={meta.data} baseUrl={isIpAddressOrDomain()} />
      )}
      {/* <NotAuth id={id} data={meta.data} baseUrl={isIpAddressOrDomain()} /> */}
      <Outlet />
    </>
  );
};

export default LayoutInfo;
