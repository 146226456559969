/*eslint-disable*/
// react
import React, { useState, useRef, useEffect } from "react";

import "../styles/tajikistan.scss";

String.prototype.splice = function (idx, str) {
  return this.slice(0, idx) + str + this.slice(idx);
};

const Tajikistan = ({ item, isDisabled }) => {
  const [center, setCenter] = useState(false);
  const [carNumber, setCarNumber] = useState("");
  const [type, setType] = useState(0);
  const [vLine, setVLine] = useState(true); //Vertical Line Visibility
  const [iconVisibility, setIconVisibility] = useState(true); //Coutry icon visiblity
  const [isNewPlate, setIsNewPlate] = useState(false);
  const [isMotoplate, setMotoPlate] = useState(false);
  const [iconFirst, setIconFirst] = useState(false);
  const [startSelection, setStart] = useState(0);
  const [endSelection, setEnd] = useState(1);
  const input = useRef();
  const [templateNumber, setTemplateNumber] = useState(false);
  const [lastLetters, setLastLetters] = useState(true);

  const Formats = [
    /^([0-9_]){4}[a-zA-Z_]{2}([0-9_]){2}$/g, // 5555 AA 55
    /^([0-9_]){3}[a-zA-Z_]{2}([0-9_]){2}$/g, // 555 AA 55
  ];

  const cnFormat = (cn) => {
    if (cn === undefined) return -1; // eslint-disable-next-line
    const t_cn = cn.toUpperCase().replace(/\s|\-/g, "");

    for (let i = 0; i < Formats.length; i++) {
      if (t_cn.match(Formats[i]) !== null) return i;
    }
    return -1;
  };

  const Templates = ["5555AA55", "555AA55"];
  const Colors = [
    { backgroundColor: "white", color: "#000000" },
    { backgroundColor: "white", color: "#000000" },
  ];

  useEffect(() => {
    input.current.focus();
    input.current.setSelectionRange(startSelection, endSelection);
    if (!isNewPlate) {
      document.addEventListener("keydown", function (event) {
        if (event.keyCode === 113) {
          setCarNumber("");
          setIsNewPlate(true);
          setTemplateNumber(true);
          setVLine(false);
          setIconFirst(false);
          setIconVisibility(false);
        }
      });
    }
  });

  const parseFormat = (cn) => {
    const i = cnFormat(cn);
    setType(i);
    switch (i) {
      case 0:
        setIconVisibility(true);
        setVLine(false);
        setIconFirst(false);
        setMotoPlate(false);
        setIsNewPlate(false);
        setCenter(false);
        return cn.splice(8, "").splice(6, "").splice(4, "");
      case 1:
        setIconFirst(false);
        setVLine(false);
        setMotoPlate(false);
        setIsNewPlate(false);
        setIconVisibility(true);
        setCenter(false);
        return cn.splice(0, "").splice(5, "").splice(8, "");
      default:
        setIconFirst(false);
        setIconVisibility(false);
        setVLine(false);
        setCenter(true);
        return cn;
    }
  };

  useEffect(() => {
    const cn = parseFormat(item);
    setCarNumber(cn);
  }, [item && templateNumber]);

  useEffect(() => {
    const cn = parseFormat(item);
    setCarNumber(cn);
  }, [item || templateNumber]);

  const selectChar = (el, isRight) => {
    if (isRight === true) {
      for (let i = el.selectionEnd; i < el.value.length; i++) {
        if (el.value[i] !== " " && el.value[i] !== "-") {
          el.setSelectionRange(i, i + 1);
          setStart(i);
          setEnd(i + 1);
          break;
        }
      }
    } else if (isRight === false) {
      for (let i = el.selectionStart; i > 0; i--) {
        if (el.value[i - 1] !== " " && el.value[i - 1] !== "-") {
          el.setSelectionRange(i - 1, i);
          setStart(i - 1);
          setEnd(i);

          break;
        }
      }
    }
  };

  const focusPlate = (el) => {
    el.focus();
    selectChar(el);
  };

  const isLetter = (str) => {
    return str.length === 1 && str.match(/[a-z]/i);
  };
  const isNumeric = (str) => {
    return /^\d+$/.test(str);
  };

  const setCharAt = (str, index, chr) => {
    if (index > str.length - 1) return str;
    return str.substr(0, index) + chr + str.substr(index + 1);
  };

  const handleCustomInputKeyDown = (event) => {
    if (event.keyCode === 112) {
      event.preventDefault();
      setIsNewPlate(false);
      setTemplateNumber(false);
      setVLine(true);
      setIconVisibility(true);
    }
  };
  const classVLine = vLine
    ? "vertical-line-tajikistan"
    : "vertical-line-tajikistan none";
  const classIcon = iconVisibility
    ? "country-icon-tajikistan"
    : "country-icon-tajikistan none";
  const countryIconFirst = iconFirst
    ? "country-icon-first-tajikistan"
    : " country-icon-first-tajikistan none";

  const handle = (event) => {
    event.preventDefault();
    if (event.keyCode === 39 || event.keyCode === 37) {
      if (event.keyCode === 39) {
        selectChar(input.current, true);
        event.preventDefault();
      } else if (event.keyCode === 37) {
        selectChar(input.current, false);
        event.preventDefault();
      }
    } else if (
      (event.keyCode >= 48 && event.keyCode <= 90) ||
      (event.keyCode >= 96 && event.keyCode <= 105)
    ) {
      const char = carNumber[input.current.selectionStart];
      if ((isNumeric(char) && isNumeric(event.key)) || char === "_") {
        const str = setCharAt(
          carNumber,
          input.current.selectionStart,
          event.key
        );
        setCarNumber(str);
        selectChar(input.current, true);
      } else if ((isLetter(char) && isLetter(event.key)) || char === "_") {
        const str = setCharAt(
          carNumber,
          input.current.selectionStart,
          event.key
        );
        setCarNumber(str);
        selectChar(input.current, true);
      }
    } else if (event.keyCode === 112) {
      // When F1 is clicked
      let tt = cnFormat(carNumber);
      const t = cnFormat(item);
      if (tt >= Templates.length - 1) tt = -1;
      tt++;

      if (tt === t) setCarNumber(parseFormat(item));
      else if (type === tt) setCarNumber(parseFormat(carNumber));
      else setCarNumber(parseFormat(Templates[tt]));
      setStart(0);
      setEnd(1);
    } else {
      focusPlate(input.current);
      event.preventDefault();
    }
  };
  const taxi = carNumber?.slice(4, 6);
  return (
    <div className={"plate"}>
      <div className={classIcon}></div>
      {iconVisibility && <span className="country-code-tajikistan">TJ</span>}
      <div className={classVLine}></div>
      <input
        onDragStart={() => {
          return false;
        }}
        onDrop={() => {
          return false;
        }}
        type="text"
        spellCheck={false}
        className={
          taxi === "TT" ? "car_number_tajikistan_taxi" : "form-control"
        }
        maxLength={12}
        id={center ? "car_number_tajikistan_center" : "car_number_tajikistan"}
        name="number_plate"
        defaultValue={carNumber}
        onKeyDown={handle}
        onMouseDown={handle}
        ref={input}
        style={Colors[type]}
        disabled={isDisabled}
      />
    </div>
  );
};
export default Tajikistan;
