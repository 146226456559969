import * as React from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Button, Stack } from "@mui/material";
import moment from "moment";
import "moment/locale/uz";
import { useTranslation } from "react-i18next";
import "./card.css";
import Plate from "./Plate";
export default function MediaCard({ data, id, baseUrl }) {
  const { t } = useTranslation();

  // const handleRuleLang = (data) => {
  //   if (i18n.language === "tj") return data?.rules?.title_tj;
  //   if (i18n.language === "ru") return data?.rules?.title_ru;
  //   if (i18n.language === "en") return data?.rules?.title_en;
  // };
  // console.log(data);
  return (
    <CardContent
      // className="card"
      sx={{
        minHeight: { xs: "24vh", sm: "24vh", md: "35.3vh" },
        position: "relative",
      }}
    >
      {/* {data?.extra && data?.extra?.violationNumber && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          mb={3}
        >
          <Typography>{t("Decision")}:</Typography>
          <Typography
            sx={{
              fontWeight: 700,
              color: !data?.extra?.violationNumber && "#3f51b5",
              textAlign: "right",
            }}
          >
            {(data?.extra && data?.extra?.violationNumber) ||
              t("The decision is not formed")}
          </Typography>
        </Stack>
      )} */}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        mb={3}
      >
        <Typography>{t("Date and time")}:</Typography>
        <Typography sx={{ fontWeight: 700, textAlign: "right" }}>
          {data?.the_date &&
            moment(data?.the_date).format("DD.MM.yyyy HH:mm:ss")}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        mb={3}
      >
        <Typography>{t("Vehicle number")}:</Typography>
        <Plate plate={data?.car_number} />
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        mb={3}
      >
        <Typography>{t("Type of violation")}:</Typography>
        <Typography sx={{ fontWeight: 700, textAlign: "right" }}>
          {/* {handleRuleLang(data)} */}
          {data?.rules}
        </Typography>
      </Stack>
      {/* {!data?.extra && data?.extra?.violationNumber && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{ xs: { display: "none" } }}
          mb={1}
        >
          <Typography>{t("Status")}:</Typography>
          <Typography
            sx={{
              fontWeight: 700,
              color:
                data?.extra && data?.extra?.violationNumber
                  ? data?.extra?.isPaid
                    ? "#43a047"
                    : "#ff1744"
                  : "#3f51b5",
              textAlign: "right",
            }}
          >
            {data?.extra && data?.extra?.violationNumber
              ? data?.extra?.isPaid
                ? t("Paid")
                : t("Unpaid")
              : t("The decision is not formed")}
          </Typography>
        </Stack>
      )} */}
      {data?.video && (
        <Button
          href={`${baseUrl}/video/download/video/${id}`}
          sx={{
            mt: 1,
            position: "absolute",
            width: "92%",
            height: "40px",
            bottom: "10px",
          }}
          variant="contained"
          // size="small"
          target="_blank"
        >
          {t("Video download")}
        </Button>
      )}
    </CardContent>
  );
}
