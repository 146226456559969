// import { Box, Typography } from "@mui/material";
import React from "react";
import CarPanel from "./carPanel/carPanel";

function Plate({ plate }) {
  return (
    // <Box
    //   sx={{
    //     display: "flex",
    //     fontWeight: 700,
    //     border: "1px solid",
    //     padding: "5px",
    //     borderRadius: "5px  ",
    //   }}
    // >
    //   <Typography sx={{}}>{plate}</Typography>
    //   <Box></Box>
    // </Box>
    <CarPanel
      car_number={plate || "0000AA00"}
      isDisabled={true}
      // country={selected?.country}
    />
  );
}

export default Plate;
