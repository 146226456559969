const ru = {
  language: "Язык",
  notifications: "Уведомления",
  account: "Аккаунт",
  profile: "Профиль",
  Apps: "Программы",
  "sign out": "Выход",
  "sign in": "Войти",
  Welcome: "Добро пожаловать",
  "Sign in to your account to continue":
    "Войдите в свою учетную запись, чтобы продолжить",
  Password: "Пароль",
  Login: "Логин",
  "Sign up": "Зарегистрироваться",
  "To register in the system, please call number":
    "Для регистрации в системе звоните по телефону +998 (99) 999 99 99.",
  day: "День",
  night: "Ночь",
  "Page not found.": "Страница не найдена.",
  "The page you are looking for might have been removed.":
    "Возможно, страница, которую вы ищете, была удалена.",
  "Return to website": "Вернуться на сайт",
  "Welcome back": "Добро пожаловать",
  lang: "ru",
  Dashboard: "Дашборд",
  "Something went wrong": "Что-то пошло не так",
  "Login is required": "Требуется логин",
  "Password is required": "Требуется пароль",
  "Log Out": "Выйти",
  "Do you really want to log out": "Вы действительно хотите выйти из системы?",
  Yes: "Да",
  No: "Нет",
  Accept: "Подтвердить",
  Reject: "Отклонить",
  Verification: "Верификация",
  Version: "Версия",
  "Modified date": "Дата изменения",
  Appmanager: "Менеджер приложений",
  FileManager: "Менеджер Файл ",
  UploadFile: "Загрузить файл",
  CreateFolder: "Создать папку",
  "First Name": "Фамилия",
  "Last Name": "Имя",
  Designation: "Belgilanish",
  "Confirmation of the fine": "Подтверждение штрафа",
  "Do you really confirm this fine":
    "Вы действительно подтверждаете этот штраф",
  "Information about the fine": "Информация о штрафе",
  Date: "Дата",
  "Fine type": "Тип правонарушения",
  Visual: "Визуально",
  "Check number": "Проверить номер",
  Location: "Местоположение",
  Image: "Фото",
  Video: "Видео",
  Size: "Размер",
  "No data for verification": "Нет данных для проверки",
  "No video": "Нет видео",
  "Do you really reject this fine": "Вы действительно отвергаете этот штраф",
  Canceled: "Отменено",
  Confirmed: "Подтверждено",
  Search: "Поиск",
  Map: "Карта",
  online: "онлайн",
  Online: "Oнлайн",
  offline: "офлайн",
  Offline: "Офлайн",
  Settings: "Настройки",
  Entrepreneurs: "Предприниматели",
  Add: "Добавить",
  Update: "Обновить",
  Directory: "Справочник",
  Name: "Название",
  INN: "ИНН",
  "Created Date": "Дата создания",
  "Expiration Date": "Дата окончания",
  Status: "Статус",
  Actions: "Действия",
  "Add Entrepreneur": "Добавить предпринимателя",
  Edit: "Редактировать",
  maximum: "Максимальное число символов не должно превышать {{num}}",
  Required: "Требуется заполнить",
  Delete: "Удалить",
  Cancel: "Отмена",
  "Remove entrepreneur?": "Удалить предпринимателя?",
  "Are you sure you want to delete the?":
    "Вы действительно хотите удалить {{name}}?",
  "Successfully edited": "Успешно изменено",
  "Successfully added": "Успешно добавлено",
  Removed: "Удалено",
  All: "Все",
  "Less than 30 days left before contract expires":
    "До истечения контракта осталось менее 30 дней",
  "Field must consist  9 or 14 numbers":
    "Поле должно состоять из 9 или 14 цифр",
  "Date must be greater than this date":
    "Дата должна быть больше сегодняшней даты",
  Objects: "Объекты",
  Object: "Объект",
  Users: "Пользователи",
  Username: "Логин",
  "Full name": "Полное имя",
  Description: "Описание",
  "Phone number": "Номер телефона",
  "Last seen": "Последнее посещение",
  "Add User": "Добавить пользователя",
  "Add Object": "Добавить объект",
  min: "Минимум {{num}} символов",
  Region: "Регион",
  Address: "Адрес",
  "Device types": "Типы устройств",
  Rules: "Типы правил",
  "Add device type": "Добавить тип устройства",
  "Add rule": "Добавить правило",
  "Only numbers": "Только цифры",
  "Device type": "Тип устройства",
  "Last update": "Последнее обновление",
  Copy: "Копировать",
  Device: "Устройство",
  "Add Computer": "Добавить компьютер",
  "Invalid IP address format": "Неверный формат IP-адреса",
  "Please add the location on the map":
    "Пожалуйста, добавьте местоположение на карту",
  Computers: "Компьютеры",
  "Add Camera": "Добавить камеру",
  "No data": "Нет данных",
  "Car number": "Номер машины",
  Dislocation: "Дислокация",
  Rule: "Правило",
  DRB: "Гос. номер",
  From: "От",
  To: "До",
  Cameras: "Камеры",
  Comment: "Комментарий",
  Code: "Код",
  "Open in telegram": "Открыть в телеграм",
  "Search by ID": "Искать по ID",
  Information: "Информация",
  paginationFooter: "Итого: {{num}} из {{num1}}",
  View: "Посмотреть",
  Restore: "Восстановить",
  "Enter your password": "Введите свой пароль",
  Successfully: "Успешно",
  Staff: "Сотрудники",
  Administrators: "Администраторы",
  Operators: "Операторы",
  Source: "Источник",
  "Select user from NTLM": "Выбрать пользователя из NTLM",
  "Fill the form": "Заполните форму",
  Next: "Следующий",
  Back: "Назад",
  Skip: "Пропустить",
  Optional: "Необязательный",
  Devices: "Устройства",
  "Devices (V2)": "Устройства (V2)",
  "Incorrect value": "Неверное значение",
  "Control (Police)": "Контроль (ГАИ)",
  Control: "Контроль",
  "Change rule": "Изменить правило",
  Permissions: "Разрешения",
  "Enter a new title": "Введите новое название",
  Stop: "Стоп",
  "YPX users": "Пользователи (ГАИ)",
  "The time the statistics were taken": "Время сбора статистики",
  Today: "Сегодня",
  Yesterday: "Вчера",
  Difference: "Разница",
  Violations: "Нарушения",
  "Cars in joint movement": "Автомобили в совместном движении",
  Speed: "Скорость",
  "Stats by rules": "Статистика по правилам",
  "Statistics of violation types by date":
    "Статистика типов нарушений по датам",
  "Operator stats": "Статистика операторов",
  "Data not found": "Данные не найдены",
  "Add API": "Добавить API",
  Bonus: "Бонус",
  "Bonus active": "Бонус актив",
  "Bonus passive": "Бонус пассив",
  "Location not found": "Местоположение не указано",
  Total: "Всего",
  score: "балл",
  "Active devices": "В полном рабочем состоянии",
  "Warning devices": "Есть частичная неисправность",
  "Error devices": "Произошла ошибка отправки информации",
  "Disabled devices": "Отключено администратором",
  YPX: "ГАИ",
  Moderators: "Модераторы",
  Engineers: "Технические инженеры",
  Workers: "Технические сотрудники",
  Worker: "Технический сотрудник",
  "Waiting status": "Не подтверждено",
  "Sending status": "Подтверждено",
  "Sent status": "Отправленные нарушения",
  "Rejected status": "Возвращенные документы",
  "Passive status": "Отмененные",
  "Canceled status": "Отмененные",
  Test: "Тест",
  Sending: "Отправка",
  Rejected: "Отклонено",
  Sent: "Отправлено",
  Resend: "Отправить повторно",
  "Access to control": "Доступ в контроль",
  "Access to verification": "Доступ к верификации",
  "Access to search": "Доступ к поиску",
  "Verification objects": "Объекты верификации",
  "Control objects": "Контрольные объекты",
  Uptime: "Время безотказной",
  Infos: " Информация",
  Errors: " Ошибки",
  Memory: "Память",
  free: "пустой",
  used: "использовал",
  CPU: "ЦПУ",
  Network: "Сеть",
  Disc: "Диск",
  " KBps": "Кбит/с",
  " Mbps": "Мбит/с",
  " Gbps": "Гбит/с",
  TX: "TX",
  RX: "РХ",

  "Cancel send": "Отменить отправку",
  Maintain: "Маинтаин",
  "Change role": "Изменить роль",
  Administrator: "Администратор",
  Boss: "Босс",
  Moderator: "Модератор",
  Operator: "Оператор",
  Engineer: "Технический инженер",
  "Manage Operators": "Управление операторами",
  "Time interval": "Временной интервал",
  Monday: "Понедельник",
  Tuesday: "Вторник",
  Wednesday: "Среда",
  Thursday: "Четверг",
  Friday: "Пятница",
  Saturday: "Суббота",
  Sunday: "Воскресенье",
  Email: "Электронная почта",
  "Personal Information": "Персональная информация",
  "Certificate Date": "Дата сертификата",
  "Advanced search filters": "Фильтры расширенного поиска",
  "Reason for rejection": "Причина отказа",
  "Influence of an external factor": "Влияние внешнего фактора",
  "The number is invalid": "Гос. номер недействителен",
  "The number is not specified": "Гос. номер не идентифицирован",
  "The rule is not broken": "Правило не нарушено",
  Message: "Сообщение",
  Type: "Тип",
  Brand: "Бренд",
  Model: "Модель",
  Color: "Цвет",
  Close: "Закрыть",
  "Working hours": "Время работы",
  Constructor: "Конструктор",
  "LK uptime": "Время работы LK",
  Sender: "Отправитель",
  "Sender uptime": "Время работы отправителя",
  Storage: "Хранилище",
  "System uptime": "Время работы системы",
  Short: "Коротко",
  Detailed: "Подробно",
  Exit: "Выход",
  "Copy table": "Копировать таблицу",
  "Table copied": "Таблица скопирована",
  ago: "назад",
  "day time": "день",
  days: "дней",
  hour: "час",
  hours: "часа",
  minute: "минутa",
  minutes: "минуты",
  second: "секунда",
  seconds: "секунды",
  "Change entrepreneur": "Сменить предпринимателя",
  "Change computer": "Сменить компьютер",
  "Change object": "Сменить объект",
  Table: "Таблица",
  Chart: "График",
  Report: "Отчет",
  Lines: "Линии",
  Polygons: "Многоугольники",
  Points: "Очки",
  Clear: "Очистить",
  Send: "Отправить",
  Line: "Линия",
  Save: "Сохранять",
  DoubleLine: "Двойная линия",
  "Stop Line": "Стоп-линия",
  "Stop sign": "Стоп",
  "Red Polygon": "Красный многоугольник",
  "Speed Polygon": "Полигон скорости",
  "Traffic light": "Светофор",
  "Add Region": "Добавить регион",
  Regions: "Регионы",
  Province: "Область",
  "Update photo": "Обновить фото",
  Warnings: "Предупреждения",
  Snapshots: "Снепшоты",
  Configurator: "Конфигуратор",
  "Operators statistics": "Статистика операторов",
  "Do you want save the changes you made to Configurator ?":
    "Вы хотите сохранить изменения, внесенные в Конфигуратор?",
  "Your changes will be lost if you don't save them!":
    "Ваши изменения будут потеряны, если вы их не сохраните!",
  "Don't Save": "Не экономить",
  Monitoring: "Мониторинг",
  Radar: "Радар",
  Crossroads: "Перекресток",
  "Last rule": "Последнее правилонарушение",
  "Last registration": "Последняя регистрация",
  "Last received data": "Последние полученные данные",
  "Never worked": "Никогда не работал",
  "No contact": "Нет связи",
  "Everything is fine": "Всё хорошо",
  "No violation": "Нарушений нет",
  "No information": "Нет информации",
  "Recive/Send": "Получить/Отправить",
  Comments: "Комментарии",
  Action: "Действие",
  "Turn on": "Включить",
  "Turn off": "Выключить",
  "Operator report": "Отчет оператора",
  Active: "Актив",
  Passive: "Пассив",
  "Rules for verify": "Правила верификации",
  "Rules for no verify": "Правила без проверки",
  "User info": "Информация о пользователе",
  Content: "Содержание",
  Telegram: "Телеграм",
  "Speed limit for trucks": "Ограничение скорости для грузовиков",
  "Speed limit for buses": "Ограничение скорости для автобусов",
  Entrepreneur: "Предприниматель",
  "Works at night": "Работает ночью",
  "Accessed date": "Дата доступа",
  External: "Внешний",
  Internal: "Внутренний",
  Token: "Токен",
  "Snepshot mavjud emas": "Нет доступных снимков",
  "Information about the violation": "Информация о нарушении",
  Decision: "Решение",
  "The decision is not formed": "Решение не сформировано",
  "Date and time": "Дата и время",
  "Vehicle number": "Номер автомобиля",
  "Type of violation": "Тип нарушения",
  "Video download": "Загрузка видео",
  "No information found": "Информация не найдена",
  "Page not found": "Страница не найдена",
};

export default ru;
