import { Box, AppBar, Grid, Toolbar, Typography } from "@mui/material";
import React from "react";
import "react-medium-image-zoom/dist/styles.css";
import Videoinfo from "./videoInfo";
import Logo from "./assets/logo.png";
import NavbarLanguagesDropdown from "../navbar/NavbarLanguagesDropdown";
import NavbarThemeChange from "../navbar/NavbarThemeChange";

function Content({ id, data, baseUrl }) {
  const [modal] = React.useState(false);

  return (
    <>
      <AppBar position="sticky" elevation={0}>
        <Toolbar style={{ minHeight: "65px" }}>
          <Grid container alignItems="center">
            <Grid item>
              <Box
                sx={{ display: "flex", alignItems: "center", height: "50px" }}
              >
                <img src={Logo} alt="logo" width={"100px"} />
                <Typography
                  sx={{ fontWeight: 700, fontSize: 23, ml: 1 }}
                ></Typography>
              </Box>
            </Grid>
            <Grid item xs />
            <Grid item>
              <NavbarThemeChange />
              <NavbarLanguagesDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <Videoinfo id={id} data={data} baseUrl={baseUrl} modal={modal} />
    </>
  );
}

export default Content;
