import * as React from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
// import { darken } from "polished";
// import { Search as SearchIcon } from "react-feather";
// import { useTranslation } from "react-i18next";

import {
  Grid,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Box,
} from "@mui/material";
import { useSnackbar } from "notistack";

import { Menu as MenuIcon } from "@mui/icons-material";

// import NavbarNotificationsDropdown from "./NavbarNotificationsDropdown";
// import NavbarMessagesDropdown from "./NavbarMessagesDropdown";
import NavbarLanguagesDropdown from "./NavbarLanguagesDropdown";
import NavbarUserDropdown from "./NavbarUserDropdown";
import NavbarThemeChange from "./NavbarThemeChange";
import { ReactComponent as Logo } from "../../vendor/logo.svg";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setPoint, setRank } from "../../redux/slices/points";
import IPPinger from "./IPPinger";
import { isIpAddressOrDomain } from "../../utils/functions";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const WhiteLogo = styled(Logo)`
  margin-left: ${(props) => props.theme.spacing(3)};
`;

const Navbar = ({ onDrawerToggle }) => {
  const info = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const token = localStorage.getItem("token");
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const getTelegramToken = () =>
    axios.get(`${isIpAddressOrDomain()}/config/hash`);

  const [hashData, setHashData] = React.useState({
    data: null,
    loading: false,
  });
  const getTlgrm = async () => {
    setHashData({ ...hashData, loading: true });
    try {
      const { data } = await getTelegramToken();
      setHashData({ data: data.data, loading: false });
    } catch (err) {
      if (err.response.data.code === 401) {
        navigate("/sign-in");
        localStorage.clear();
      }
      enqueueSnackbar(err.response.data.message.en, {
        variant: "error",
      });

      setHashData({ data: null, loading: false });
    }
  };

  React.useEffect(() => {
    hashData.data === null && getTlgrm();
    if (hashData.data) {
      dispatch(setPoint(hashData?.data?.points));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    if (hashData.data) {
      dispatch(setPoint(hashData?.data?.points));
      dispatch(setRank(hashData?.data?.rank));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hashData.data]);

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item sx={{ display: { xs: "block", md: "none" } }}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item>
              {(info?.user?.group_id === -1 || info?.user?.group_id === -6) && (
                <Box>
                   <img alt="Logo" src="../../vendor/logo.png" />
                </Box>
              )}
            </Grid>
            <Grid item xs />
            {/* {info?.user?.group_id === -1 && <NavbarPoints />} */}
            {/* {info?.user?.group_id === -1 && <NavbarRank />} */}
            <Grid item>
              {/* {info?.user?.id === 1 && <CommandsForAdmin />} */}
              {info?.user?.group_id !== -1 && <IPPinger />}
              {/* {(info?.user?.group_id === 0 || info?.user?.group_id > 0) &&
                !hashData.loading &&
                hashData.data && <NavbarQR tlgrm={hashData?.data?.hash} />} */}
              <NavbarThemeChange />
              {/* {info?.user?.group_id === 0 && <NavbarNotificationsDropdown />} */}
              <NavbarLanguagesDropdown />
              <NavbarUserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(Navbar);
