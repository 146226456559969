// import CarPlate from "./car_plate";
// import Russia from "./countries/Russia";
// import Kazakhstan from "./countries/Kazakhstan";
import Tajikistan from "./countries/Tajikistan";
// import Turkmenistan from "./countries/Turkmenistan";
// import Ukraine from "./countries/Ukraine";
// import Kyrgyzstan from "./countries/Kyrgyzstan";
// require("./index.scss");
// import "./index.scss";

const CarPanel = ({
  car_number,
  setNumber,
  isDisabled = true,
  searchFocused = false,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Tajikistan
        item={car_number}
        isDisabled={isDisabled}
        setNumber={setNumber}
        searchFocused={searchFocused}
      />
    </div>
  );
};

export default CarPanel;
