/* eslint-disable jsx-a11y/alt-text */
import * as React from "react";
import Box from "@mui/material/Box";
// import Grid from "@mui/material/Unstable_Grid2";
import {
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import "./style.css";
import Zoom from "react-medium-image-zoom";
import { useTranslation } from "react-i18next";
import MediaCard from "../card/Card";
import noVideoIcon from "../assets/noun-video-1249287.svg";
import axios from "axios";
import { useEffect } from "react";
import noImage from "../assets/noun-no-image-5886457.svg";
import noLocation from "../assets/no-location.svg";
import BasicMap from "../../../pages/map/BasicMap";
import useWindowSize from "../../size/useWindowSize";
import Loader from "../../Loader";

export default function VideoInfo({ id, data, baseUrl, modal }) {
  const { t } = useTranslation();
  const [fullPhoto, setFullPhoto] = React.useState({
    data: null,
    loading: true,
  });
  const [carPhoto, setCarPhoto] = React.useState({ data: null, loading: true });
  const [loading, setLoading] = React.useState(true);
  const [width, height] = useWindowSize();
  const handlephoto = async () => {
    try {
      setFullPhoto({ data: null, loading: true });
      await axios
        .get(`${baseUrl}/video/raw/full_photo/${id}`)
        .then((result) => {
          setFullPhoto({ data: result.status, loading: false });
        });
    } catch (error) {
      setFullPhoto({ data: null, loading: false });
    }
  };

  const handleFullPhoto = async () => {
    try {
      setCarPhoto({ data: null, loading: true });
      await axios.get(`${baseUrl}/video/raw/car_photo/${id}`).then((result) => {
        setCarPhoto({ data: result.status, loading: false });
      });
    } catch (error) {
      setCarPhoto({ data: null, loading: false });
    }
  };
  useEffect(() => {
    handlephoto();
    handleFullPhoto();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  setTimeout(() => {
    setLoading(false);
  }, 2000);
  return (
    <Container
      //  maxWidth={"lg"}
      sx={{ padding: "20px" }}
    >
      <Grid container spacing={2} direction={"row"}>
        <Grid container {...{ xs: 12, sm: 12, md: 8, lg: 8 }} item spacing={2}>
          <Grid {...{ xs: 12, sm: 12, md: 6 }} item>
            <Paper
              sx={{
                border: "1px solid rgba(57, 80, 57, 0.443)",
                borderRadius: "10px",
                height: "35vh",
              }}
            >
              <Typography sx={{ p: 2, m: 0 }} variant="body1" align="left">
                {t("Image")}
              </Typography>
              <Divider light={true} />
              <Box
                sx={{
                  height: "89%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  p: 1,
                  // border: "1px solid red",
                }}
              >
                {carPhoto.loading ? (
                  <Loader />
                ) : (
                  <Zoom
                    overlayBgColorStart="rgba(0, 0, 0, 0.8)"
                    overlayBgColorEnd={"rgba(0, 0, 0, 0.8)"}
                    wrapStyle={
                      {
                        // marginTop: "15px",
                        // width: "100%",
                        // aspectRatio: "16/9",
                      }
                    }
                  >
                    <img
                      loading="lazy"
                      src={
                        carPhoto.data === 200
                          ? `${baseUrl}/video/raw/car_photo/${id}`
                          : noImage
                      }
                      alt="Car"
                      style={{
                        width: "100%",
                        margin: "auto",
                        height: "28.5vh",
                        aspectRatio: "16/9",
                        objectFit: "contain",
                        backgroundColor: "#000",
                      }}
                    />
                  </Zoom>
                )}
              </Box>
            </Paper>
          </Grid>

          <Grid
            justifyItems={"center"}
            {...{ xs: 12, sm: 12, md: 6, lg: 6 }}
            item
          >
            <Paper
              sx={{
                border: "1px solid rgba(57, 80, 57, 0.443)",
                borderRadius: "10px",
                height: "35vh",
              }}
            >
              <Typography sx={{ p: 2, m: 0 }} variant="body1" align="left">
                {t("Image")}
              </Typography>
              <Divider light={true} />
              <Box
                sx={{
                  height: "89%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  p: 1,
                }}
              >
                {fullPhoto.loading ? (
                  <Loader />
                ) : (
                  <Zoom
                    overlayBgColorStart={"rgba(0, 0, 0, 0.8)"}
                    overlayBgColorEnd={"rgba(0, 0, 0, 0.8)"}
                    wrapStyle={{
                      // width: "100%",
                      objectFit: "contain",
                      aspectRatio: "16/9",
                    }}
                  >
                    <img
                      src={
                        fullPhoto.data === 200
                          ? `${baseUrl}/video/raw/full_photo/${id}`
                          : noImage
                      }
                      alt="img"
                      loading="lazy"
                      style={{
                        width: "100%",
                        margin: "auto",
                        aspectRatio: "16/9",
                        objectFit: "contain",
                        height: "28.5vh",
                        backgroundColor: "#000",
                        // marginTop: "15px",
                      }}
                    />
                  </Zoom>
                )}
              </Box>
            </Paper>
          </Grid>

          {data?.video && (
            <Grid {...{ xs: 12, sm: 12, md: 12, lg: 12 }} item>
              <Paper
                sx={{
                  border: "1px solid rgba(57, 80, 57, 0.443)",
                  borderRadius: "10px",
                  position: "relative",
                }}
              >
                <Typography sx={{ p: 2, m: 0 }} variant="body1" align="left">
                  {t("Video")}
                </Typography>
                <Divider light={true} />
                <Box
                  sx={{
                    m: 1,
                    p: 1,
                    pt: 1,
                    display: "flex",
                    maxHeight: "34.5vh",
                  }}
                  component={"div"}
                >
                  {data?.video ? (
                    !loading ? (
                      <video
                        width="100%"
                        controls
                        src={`${baseUrl}/video/raw/video/${id}`}
                        onLoadedData={() => {
                          setLoading(false);
                        }}
                      />
                    ) : (
                      <video
                        width="100%"
                        controls
                        src={noImage}
                        onLoadedData={() => {
                          setLoading(false);
                        }}
                      />
                    )
                  ) : (
                    <Box
                      sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        height: "34vh",
                      }}
                      width={"100%"}
                    >
                      <img alt="img" src={noVideoIcon} width={180} />
                    </Box>
                  )}
                </Box>
              </Paper>
            </Grid>
          )}
        </Grid>
        <Grid {...{ xs: 12, sm: 12, md: 4, lg: 4 }} item>
          <Stack spacing={2}>
            <Paper
              sx={{
                border: "1px solid rgba(57, 80, 57, 0.443)",
                borderRadius: "10px",
                height: "35vh",

                // height: "34vh !important",
              }}
            >
              <Typography sx={{ p: 2, m: 0 }} variant="body1" align="left">
                {t("Location")}
              </Typography>
              <Divider light />
              <Box
                sx={{
                  width: "100%",
                  height: "30vh",
                  p: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "end",
                }}
              >
                <Box sx={{ width: "100%", height: "97%" }}>
                  {data?.location ? (
                    <BasicMap
                      resize1={width}
                      resize2={height}
                      // zoom={20}
                      pos={[data?.location.lat, data?.location.lng]}
                    />
                  ) : (
                    <img
                      src={noLocation}
                      alt="img"
                      style={{
                        width: "100%",
                        // margin: "auto",
                        aspectRatio: "16/9",
                        objectFit: "contain",
                        height: "28.5vh",
                        // marginTop: "15px",
                      }}
                    />
                  )}
                </Box>
              </Box>
            </Paper>
            <Paper
              sx={{
                border: "1px solid rgba(57, 80, 57, 0.443)",
                borderRadius: "10px",
                // height: "35vh !important",
              }}
            >
              <Typography sx={{ p: 2, m: 0 }} variant="body1" align="left">
                {t("Information about the violation")}
              </Typography>
              <Divider light />
              <MediaCard id={id} data={data} baseUrl={baseUrl} />
            </Paper>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}
