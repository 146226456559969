import axiosInstance from "./axios";

const getVideo = (id) => axiosInstance.get(`/video/raw/${id}`);

const videoDownloadApi = (id) => axiosInstance.get(`/video/download/${id}`);

const getMeta = (id) => axiosInstance.get(`/video/meta/${id}`);

const AppApi = {
  getVideo,
  videoDownloadApi,
  getMeta,
};

export default AppApi;
