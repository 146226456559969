import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@mui/material";
import axiosInstance from "../../utils/axios";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Flag = styled.img`
  border-radius: 50%;
  width: 22px;
  height: 22px;
`;

// uz: {
//   icon: "/static/img/flags/uz.png",
//   name: "O`zbekcha",
// },
// cr: {
//   icon: "/static/img/flags/uz.png",
//   name: "Ўзбекча",
// },
const languageOptions = {
  tj: {
    icon: "/static/img/flags/tj.png",
    name: "Тоҷикӣ",
  },
  ru: {
    icon: "/static/img/flags/ru.png",
    name: "Русский",
  },
  en: {
    icon: "/static/img/flags/us.png",
    name: "English",
  },
};

function NavbarLanguagesDropdown() {
  const { t, i18n } = useTranslation();
  const [anchorMenu, setAnchorMenu] = useState(null);
  const user = localStorage.getItem("user");
  const selectedLanguage = languageOptions[i18n.language];

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const langName = localStorage.getItem("lang");

  const getLangEndpoint = "/users/lang";

  const changeLang = async (lang) => {
    if (!!user) {
      const { data } = await axiosInstance.post(getLangEndpoint, {
        lang: lang,
      });
      localStorage.setItem("lang", data?.data.lang);
    } else {
      localStorage.setItem("lang", lang);
    }
  };

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    changeLang(language);
    closeMenu();
  };

  useEffect(() => {
    if (langName) {
      i18n.changeLanguage(langName);
    }
  }, [i18n, langName]);

  return (
    <React.Fragment>
      <Tooltip title={t("language")}>
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <Flag src={selectedLanguage?.icon} alt={selectedLanguage?.name} />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        {Object.keys(languageOptions).map((language) => (
          <MenuItem
            key={language}
            onClick={() => handleLanguageChange(language)}
          >
            {languageOptions[language].name}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}

export default NavbarLanguagesDropdown;
