import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import Image from "./assets/nodata.svg";

import { Paper, Stack, Typography } from "@mui/material";

function NoData() {
  const { t } = useTranslation();
  return (
    <Paper sx={{ height: "100vh" }}>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{ height: "calc(100vh - 200px)" }}
      >
        <Helmet title="No data" />
        <img src={Image} alt="empty" style={{ maxWidth: 500 }} />
        <Typography component="div" variant="h5" align="center" gutterBottom>
          {t("No information found")}
        </Typography>
      </Stack>
    </Paper>
  );
}

export default NoData;
