import axios from "axios";

import { isIpAddressOrDomain } from ".././../utils/functions";
const axiosInstance = axios.create({
  baseURL: isIpAddressOrDomain(),
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error?.response?.status === 401 ||
      error?.response?.data?.code === 401
    ) {
      localStorage.clear();
      window.location.reload();
    }
    return Promise.reject(
      (error?.response?.data ? error?.response?.data : error?.response) ||
        "Something went wrong"
    );
  }
);

export default axiosInstance;
