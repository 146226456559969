const tj = {
  language: "Забон",
  notifications: "Хабардиҳи",
  account: "Аккаунт",
  profile: "Профил",
  Apps: "Барномаҳо",
  "sign out": "Баромад",
  "sign in": "Ворид",
  Welcome: "Хуш омадед",
  "Sign in to your account to continue":
    "Барои идома додан ба утоқи шахсӣ ворид шавед",
  Password: "Рамз",
  Login: "Логин",
  "Sign up": "Ба қайд гирифтан",
  "To register in the system, please call number":
    "Барои ба қайд гирифтан ба рақамҳои +998 (99) 999 99 99. занг занед ",
  day: "Рӯз",
  night: "Шаб",
  "Page not found.": "Саҳифа ёфта нашуд.",
  "The page you are looking for might have been removed.":
    "Саҳифа нест карда шудааст.",
  "Return to website": "Баргаштан ба сомона",
  "Welcome back": "Хуш омадед",
  lang: "tj",
  Dashboard: "Панели асбобҳо",
  "Something went wrong": "Чизе хато рафт",
  "Login is required": "Логин лозим",
  "Password is required": "Рамз лозим",
  "Log Out": "Баромад",
  "Do you really want to log out":
    "Шумо дар ҳақиқатан мехоҳед аз система бароед?",
  Yes: "Бале",
  No: "Не",
  Accept: "Тасдиқ кардан",
  Reject: "Раъд кардан",
  Verification: "Тафтиш",
  Version: "Версия",
  "Modified date": "Санаи тағийрот",
  Appmanager: "Менеҷери замимаҳо",
  FileManager: "Менеҷери файл ",
  UploadFile: "Боргирии файл",
  CreateFolder: "Сохтани папка",
  "First Name": "Насаб",
  "Last Name": "Ном",
  Designation: "Belgilanish",
  "Confirmation of the fine": "Тасдиқи ҷарима",
  "Do you really confirm this fine":
    "Шумо дар ҳақиқат ин ҷаримаро тасдиқ мекунед?",
  "Information about the fine": "Информация о штрафе",
  Date: "Сана",
  "Fine type": "Шакли ҳуқуқвайронкуни",
  Visual: "Визуали",
  "Check number": "Тафтиши рақам",
  Location: "Ҷойгиршавӣ",
  Image: "Расм",
  Video: "Видео",
  Size: "Ҳаҷм",
  "No data for verification": "Барои тафтиш маълумот нест",
  "No video": "Видео нест",
  "Do you really reject this fine":
    "Шумо дар ҳақиқат ин ҷаримаро инкор мекунед?",
  Canceled: "Инкор шуд",
  Confirmed: "Тасдиқ шуд",
  Search: "Ҷустуҷӯй",
  Map: "Харита",
  online: "онлайн",
  Online: "Oнлайн",
  offline: "офлайн",
  Offline: "Офлайн",
  Settings: "Танзимотҳо",
  Entrepreneurs: "Соҳибкорон",
  Add: "Илова намудан",
  Update: "Навсози",
  Directory: "Маълумот",
  Name: "Номгузори",
  INN: "РМА",
  "Created Date": "Санаи сохтан",
  "Expiration Date": "Санаи анҷом",
  Status: "Мақом",
  Actions: "Амалҳо",
  "Add Entrepreneur": "Иловаи соҳибкор",
  Edit: "Таҳрир кардан",
  maximum: "Шумораи максималии аломатҳо набояд зиёд бошад {{num}}",
  Required: "Пур кардан лозим аст",
  Delete: "Нест кардан",
  Cancel: "Инкор",
  "Remove entrepreneur?": "Хат задани соҳибкор?",
  "Are you sure you want to delete the?":
    "Вы действительно хотите удалить {{name}}?",
  "Successfully edited": "Муввафақона иваз шуд",
  "Successfully added": "Муввафақона илова шуд",
  Removed: "Нест шуд",
  All: "Ҳама",
  "Less than 30 days left before contract expires":
    "То анҷоми шартнома на кам аз 30 рӯз монд",
  "Field must consist  9 or 14 numbers":
    "Равзана бояд аз 9 ё ин ки 14 рақам пур бошад",
  "Date must be greater than this date":
    "Сана аз санаи имрӯза бояд зиёдтар бошад",
  Objects: "Обектҳо",
  Object: "Обект",
  Users: "Истифодабарандагон",
  Username: "Логин",
  "Full name": "Номи пурра",
  Description: "Тавсиф",
  "Phone number": "Рақами телефон",
  "Last seen": "Охирон воридот",
  "Add User": "Илова намудани истифодабаранда",
  "Add Object": "Илова намудани обект",
  min: "Минимум {{num}} аломатҳо",
  Region: "Минтақа",
  Address: "Суроға",
  "Device types": "Намуди таҷҳизот",
  Rules: "Намуди қоидаҳо",
  "Add device type": "Илова намудани намуди таҷҳизот",
  "Add rule": "Добавить правило",
  "Only numbers": "Танҳо рақамҳо",
  "Device type": "Намуди таҷҳизот",
  "Last update": "Охирон навсозӣ",
  Copy: "Нусхагири",
  Device: "Таҷҳизот",
  "Add Computer": "Иловаи компютер",
  "Invalid IP address format": " Формати нодурусти IP-суроға",
  "Please add the location on the map":
    "Илтимос ҷойгиршавиро дар харита илова намоед",
  Computers: "Компютерҳо",
  "Add Camera": "Иловаи камера",
  "No data": "Маълумот нест",
  "Car number": "Рақами нақлиёт",
  Dislocation: "Дислокатсия",
  Rule: "Қоида",
  DRB: "Гос. Рақам",
  From: "Аз",
  To: "То",
  Cameras: "Камерҳо",
  Comment: "Комментарияҳо",
  Code: "Рамз",
  "Open in telegram": "Кушодан  дар Телеграмм",
  "Search by ID": "Ҷутуҷӯ бо ID",
  Information: "Маълумот",
  paginationFooter: "Дар умум} аз{num1}}",
  View: "Дидан",
  Restore: "Барқарор кардан",
  "Enter your password": "Рамзи худро ворид созед",
  Successfully: "Бомуввафақият",
  Staff: "Кормандон",
  Administrators: "Администраторҳо",
  Operators: "Операторҳо",
  Source: "Сарчашма",
  "Select user from NTLM": "Интихоби истифодабаранда аз NTLM",
  "Fill the form": "Шаклро пур кунед",
  Next: "Баъди",
  Back: "Ба ақиб",
  Skip: "Гузарондан",
  Optional: "Зарур нест",
  Devices: "Таҷҳизот",
  "Devices (V2)": "Таҷҳизот",
  "Incorrect value": "Маънии нодуруст",
  "Control (Police)": "Идора (ГАИ)",
  Control: "Идора",
  "Change rule": "Ивази қоида",
  Permissions: "Иҷозат",
  "Enter a new title": "Намгузории навро ворид созед",
  Stop: "Стоп",
  "YPX users": "Истифодабаранда (ГАИ)",
  "The time the statistics were taken": "Вақти ҷамъоварии статистика",
  Today: "Ҳамин рӯз",
  Yesterday: "Дирӯз",
  Difference: "Фарқият",
  Violations: "Вайронкуни",
  "Cars in joint movement": "Автомобил якҷоя бо ҳаракат",
  Speed: "Суръат",
  "Stats by rules": "Статистика бо қоида",
  "Statistics of violation types by date":
    "Статистикаи  намуди қоидавайронкуниҳо ба сана",
  "Operator stats": "Статистикаи операторон",
  "Data not found": "Маълумот ёфт нашуд",
  "Add API": "Иловаи API",
  Bonus: "Бонус",
  "Bonus active": "Бонус актив",
  "Bonus passive": "Бонус пассив",
  "Location not found": "Ҷойгиршави қайд нашудааст",
  Total: "Дар умум",
  sum: "сум",
  "Active devices": "Дар ҳолати пурраи коршоями",
  "Warning devices": "Дар ҳолати қисман корношоям будан",
  "Error devices": "Ҳангоми фиристодани маълумот хатогӣ рух дод",
  "Disabled by admin": "Администратор хомуш кард",
  "Disabled devices": "Таҷҳизоти хомуш",
  YPX: "БДА",
  Moderators: "Модераторҳо",
  Engineers: "Инжинерҳои техникӣ",
  Workers: "Кормандони техникӣ",
  Worker: "Ҳамкорони техникӣ",
  "Waiting status": "Тасдиқ нашудааст",
  "Sending status": "Тасдиқ шудааст",
  "Sent status": "қоидавайронкуни фиристода шуд",
  "Rejected status": "Ҳуҷҷатҳои баргардонидашуда",
  "Passive status": "Инкоршуда",
  "Canceled status": "Инкоршуда",
  Test: "Тест",
  Sending: "Фиростода",
  Rejected: "Раъд шуда",
  Sent: "Фиристода шуда",
  Resend: "Аз нав фиристодан",
  "Access to control": "Дастраси дар идора",
  "Access to verification": "Дастраси дар верификатсия",
  "Access to search": "Дастраси дар ҷустуҷӯ",
  "Verification objects": "Объектҳои верификатсия",
  "Control objects": "Обектҳои идорашаванда",
  Uptime: "Вақти инкорнашаванда",
  Infos: " Маълумот",
  Errors: " Хатоги",
  Memory: "Хотира",
  free: "Холи",
  used: "Истифода бурда",
  CPU: "СПУ",
  Network: "Мавҷ",
  Disc: "Диск",
  " KBps": "Кбит/с",
  " Mbps": "Мбит/с",
  " Gbps": "Гбит/с",
  TX: "TX",
  RX: "РХ",
  "Cancel send": "Раъди фиристодан",
  Maintain: "Мақом",
  "Change role": "Ивази нақш",
  Administrator: "Администратор",
  Boss: "Сардор",
  Moderator: "Модератор",
  Operator: "Оператор",
  Engineer: "Технический инженер",
  "Manage Operators": "Идораи оператор",
  "Time interval": "Интервали муввақати",
  Monday: "Душанбе",
  Tuesday: "Сешанбе",
  Wednesday: "Чоршанбе",
  Thursday: "Панҷшанбе",
  Friday: "Ҷумъа",
  Saturday: "Шанбе",
  Sunday: "Якшанбе",
  Email: "Почтаи электронӣ",
  "Personal Information": "Маълумоти шахсӣ",
  "Certificate Date": "Санаи сертификат",
  "Advanced search filters": "Фильтрҳои васеъи ҷустуҷӯ",
  "Reason for rejection": "Сабаби раъд",
  "Influence of an external factor": "Таъсири факторҳои беруна",
  "The number is invalid": "Гос. Рақам эътибор надорад",
  "The number is not specified": "Рақами давлатӣ ба қайд гирифта нашудааст",
  "The rule is not broken": "Қоида вайрон нашудааст",
  Message: "Паёмак",
  Type: "Навъ",
  Brand: "Бренд",
  Model: "Тамға",
  Color: "Ранг",
  Close: "Пӯшидан",
  "Working hours": "Вақти кор",
  Constructor: "Конструктор",
  "LK uptime": "Вақти кор LK",
  Sender: "Ирсолкунанда",
  "Sender uptime": "Вақти кории ирсолкунанда",
  Storage: "Нигоҳдори",
  "System uptime": "Вақти кории система",
  Short: "Кӯтоҳ",
  Detailed: "Тафсилот",
  Exit: "Баромад",
  "Copy table": "Нусхагирии ҷадвал",
  "Table copied": "Ҷадвал нусхагири шуд",
  ago: "Бозгашт",
  "day time": "Рӯз",
  days: "Рӯз",
  hour: "Соат",
  hours: "Сотҳо",
  minute: "Дақиқа",
  minutes: "Дақиқаҳо",
  second: "Сония",
  seconds: "Сонияҳо",
  "Change entrepreneur": "Ивази соҳибкор",
  "Change computer": "Ивази компютер",
  "Change object": "Ивази обект",
  Table: "Ҷадвал",
  Chart: "Реҷа",
  Report: "Ҳисобот",
  Lines: "Хатҳо",
  Polygons: "Бисёркунҷа",
  Points: "Холҳо",
  Clear: "Тоза кардан",
  Send: "Равон кардан",
  Line: "Хат",
  Save: "Сабт кардан",
  DoubleLine: "Хати дугона",
  "Stop Line": "Аломати стоп",
  "Stop sign": "Стоп",
  "Red Polygon": "Бисёркунҷаи сурх",
  "Speed Polygon": "Полигон суръат",
  "Traffic light": "Чароғак",
  "Add Region": "Иловаи минтақа",
  Regions: "Минтақа",
  Province: "Вилоят",
  "Update photo": "навсозии расм",
  Warnings: "Огоҳи",
  Snapshots: "Снепшотҳо",
  Configurator: "Конфигуратор",
  "Operators statistics": "Статистикаи операторон",
  "Do you want save the changes you made to Configurator ?":
    "Шумо мехоҳед ивазро дар конфигуратор гузоред?",
  "Your changes will be lost if you don't save them!":
    "Ивазҳои шумо нест мешаванд,агар онро сабт накунед!",
  "Don't Save": "Сарфакори накардан",
  Monitoring: "Мониторинг",
  Radar: "Радар",
  Crossroads: "Чорроҳа",
  "Last rule": "Охирон қоидавайронкуни",
  "Last registration": "Охирон бақайдгири",
  "Last received data": "Охирон гирифти маълумот",
  "Never worked": "Умуман кор накардааст",
  "No contact": "Мавҷ нест",
  "Everything is fine": "Ҳама хуб",
  "No violation": "Қоидавайронкуни нест",
  "No information": "Маълумот нест",
  "Recive/Send": "Қабул кардан/фиристодан",
  Comments: "Комментарияҳо",
  Action: "Фаъолият",
  "Turn on": "Фаъол кардан",
  "Turn off": "Хомуш кардан",
  "Operator report": "Ҳисоботи оператор",
  Active: "Актив",
  Passive: "Пассив",
  "Rules for verify": "Қоидаҳои верификатсия",
  "Rules for no verify": "Қоидаҳо бе тафтиш",
  "User info": "Маълумот дар бораи истифодабаранда",
  Content: "Мундариҷа",
  Telegram: "Телеграм",
  "Speed limit for trucks": "Маҳдудияти суръат барои мошинҳои боркаш",
  "Speed limit for buses": "Маҳдудияти суръат барои автобус",
  Entrepreneur: "Соҳибкор",
  "Works at night": "Фаъолияти шабона",
  "Accessed date": "Санаи дастраси",
  "Sended date": "Санаи фиристодан",
  "Report by camera (Violations)": "Ҳисоботи камера (қоидавайронкуни)",
  Range: "Диапазон",
  Weekly: "Ҳар ҳафтаина",
  Monthly: "Ҳар моҳа",
  Daily: "Ҳар рӯза",
  "Number of violations": "Миқдори қоидавайронкуниҳо",
  "Show all": "Намоиши пурра",
  "Read all": "Хонда",
  "No new notifications": "хабарҳои нав нест",
  "New notifications": "Хабарҳои нав",
  "Operator Logs": "Журнали операторон",
  New: "Нав",
  Old: "Куҳна",
  "Snepshot mavjud emas": "Сурати фаврӣ мавҷуд нест",
  "Information about the violation": "Маълумот дар бораи вайронкунӣ",
  Decision: "Қарор",
  "The decision is not formed": "Қарор таҳия нашудааст",
  "Date and time": "Сана ва вақт",
  "Vehicle number": "Рақами мошин",
  "Type of violation": "Намуди вайронкунӣ",
  "Video download": "Видео зеркашӣ",
  "No information found": "Маълумоте ёфт нашуд",
  "Page not found": "Саҳифа ёфт нашуд",
};

export default tj;
