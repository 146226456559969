import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import Image from "./assets/error404.svg";

import { Paper, Stack, Typography } from "@mui/material";

function Page404() {
  const { t } = useTranslation();
  return (
    <Paper sx={{ height: "100vh" }}>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{ height: "calc(100vh - 200px)" }}
      >
        <Helmet title="404 Error" />
        <img src={Image} alt="404" style={{ maxWidth: 500 }} />
        <Typography component="div" variant="h5" align="center" gutterBottom>
          {t("Page not found")}
        </Typography>
      </Stack>
    </Paper>
  );
}

export default Page404;
