import React from "react";
import { useFormik } from "formik";
import FileUpload from "react-material-file-upload";
import { useState } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { isIpAddressOrDomain } from "../../../../../utils/functions";

const validate = (values) => {
  const errors = {};

  if (!values.version) {
    errors.version = "Required";
  } else if (
    !new RegExp(/^([0-9]{1,6})\.([0-9]{1,6})\.([0-9]{1,6})$/).test(
      values.version
    )
  ) {
    errors.version = "Invalid version";
  }

  return errors;
};

const SignupForm = ({ setOpen, name, open, fetchUser }) => {
  const [files, setFiles] = useState(null);
  const [initialValues] = useState({
    version: "",
  });
  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const url = `${isIpAddressOrDomain()}/app/${name}`;
  const handleClose = () => {
    setOpen(false);
    formik.values.version = "";
    setFiles(null);
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (values) => {},
  });

  const fileCatch = async () => {
    try {
      if (
        files !== null &&
        formik.errors.version !== "Required" &&
        formik.errors.version !== "Invalid version"
      ) {
        const formData = new FormData();
        formData.append("app", files[0]);
        formData.append("version", formik.values.version);
        setLoading(true);
        let contentType = "";
        if (files[0].name.endsWith(".exe")) {
          contentType = "application/x-msdownload"; // Content type for .exe files
        } else if (files[0].name.endsWith(".zip")) {
          contentType = "application/zip-compressed"; // Content type for .zip files
        }
        await axios.post(url, formData, {
          headers: {
            "Content-Type": contentType,
          },
        });

        //Unrefresh page
        fetchUser();
        enqueueSnackbar(t("Successfully added"), { variant: "success" });
        handleClose();

        setLoading(false);
      } else {
        enqueueSnackbar("No selected file", { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar(
        error.response.data.error
          ? error.response.data.error
          : error.response.data.message.en,
        {
          variant: "error",
        }
      );
      setLoading(false);

      handleClose();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent sx={{ width: 400 }}>
          <TextField
            autoFocus
            margin="dense"
            id="version"
            name="version"
            type="text"
            label="version"
            onChange={formik.handleChange}
            value={formik.values.version}
            fullWidth
            error={Boolean(formik.errors.version)}
            helperText={formik.touched.version && formik.errors.version}
          />

          <Box>
            <FileUpload
              value={files}
              onChange={setFiles}
              accept=".exe , .zip"
              multiple={false}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" onClick={handleClose}>
            {t("Cancel")}
          </Button>
          <LoadingButton
            type="submit"
            onClick={fileCatch}
            loadingPosition="end"
            variant="contained"
            color="primary"
            disabled={loading}
            endIcon={<SendIcon />}
          >
            {t("Add")}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default SignupForm;
