import React from "react";
// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
// Guards
import AuthGuard from "./components/guards/AuthGuard";

import { checkPermission, childrenRoutes, getUserType } from "./permissions";
import { Navigate } from "react-router-dom";
import LayoutInfo from "./components/ViolationInfo";

const getRoutes = (user) => {
  const location = window.location;
  const userPermissionArray = getUserType(user);

  return [
    {
      path: ":id",
      element: <LayoutInfo user={user} />,
    },

    {
      path: "",
      element: (
        <AuthGuard>
          <DashboardLayout map={location.pathname === "/map"} />
        </AuthGuard>
      ),
      children: childrenRoutes.main.filter((route) =>
        checkPermission(userPermissionArray, route.path)
      ),
    },
    {
      path: "",
      element: <AuthLayout />,
      children: childrenRoutes.auth.filter((route) =>
        checkPermission(userPermissionArray, route.path)
      ),
    },
    {
      path: "*",
      element: <Navigate to={userPermissionArray[0]} />,
    },
    {
      path: "/",
      element: <Navigate to={userPermissionArray[0]} />,
    },
  ];
};

export default getRoutes;
